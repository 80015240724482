export const releaseItems = [
    {
        index: 0,
        catalog: 'BASTANT001R',
        artist: 'Jonas Dunkel',
        title: 'Bongo Music (Remastered)',
        imgUrl:
            'https://img.discogs.com/Pm2zOxm_R0PoAgbbPecB1pboifs=/fit-in/600x600/filters:strip_icc():format(jpeg):mode_rgb():quality(90)/discogs-images/R-13253327-1551948873-2387.jpeg.jpg',
        discogsUrl: 'https://www.discogs.com/Jonas-Dunkel-Bongo-Music-Remastered/release/13253327',
        spotifyUrl: 'https://open.spotify.com/album/2GYInIDSCIPmiPtoxhWOHo'
    },
    {
        index: 1,
        catalog: 'BASTANT002',
        artist: 'Jonas Dunkel',
        title: "This Ain't Your Grandmother",
        imgUrl:
            'https://img.discogs.com/A1YfZtoKPnuugk7uaCHrUizXPxA=/fit-in/600x600/filters:strip_icc():format(jpeg):mode_rgb():quality(90)/discogs-images/R-13315912-1551948480-1250.jpeg.jpg',
        discogsUrl:
            'https://www.discogs.com/Jonas-Dunkel-This-Aint-Your-Grandmother/release/13315912',
        spotifyUrl: 'https://open.spotify.com/album/3jSkf16xXxdjMfK8uxk8VT'
    },
    {
        index: 2,
        catalog: 'BASTANT003',
        artist: 'Jonas Dunkel',
        title: 'The Looper (Remastered)',
        imgUrl:
            'https://img.discogs.com/Z9YtUfrCJjzLGqY8-fR7OZzQAg4=/fit-in/600x600/filters:strip_icc():format(jpeg):mode_rgb():quality(90)/discogs-images/R-13347106-1552506465-5363.jpeg.jpg',
        discogsUrl: 'https://www.discogs.com/Jonas-Dunkel-The-Looper-Remastered/release/13347106',
        spotifyUrl: 'https://open.spotify.com/album/3t0uprrhuRwvC6fFwIYm1v'
    },
    {
        index: 3,
        catalog: 'BASTANT004',
        artist: 'Stoft feat. Systrarna Freij',
        title: 'Brokenhearted',
        imgUrl:
            'https://img.discogs.com/JW1KGnXeXrAQ1gPaAMhZA9i4v2A=/fit-in/600x600/filters:strip_icc():format(jpeg):mode_rgb():quality(90)/discogs-images/R-13387066-1553251679-3578.jpeg.jpg',
        discogsUrl: 'https://www.discogs.com/Stoft-Brokenhearted/release/13387066',
        spotifyUrl: 'https://open.spotify.com/album/0pEFNlbCJzuLeneqGXOXvQ'
    },
    {
        index: 4,
        catalog: 'BASTANT005',
        artist: 'Stoft feat. Tuva-Li',
        title: 'Lillasysters Sång',
        imgUrl:
            'https://img.discogs.com/ji0QEPnuebsV7-f_AnYSQvHbGxM=/fit-in/600x600/filters:strip_icc():format(jpeg):mode_rgb():quality(90)/discogs-images/R-13387097-1553252311-9045.jpeg.jpg',
        discogsUrl: 'https://www.discogs.com/Stoft-Lillasysters-S%C3%A5ng/release/13387097',
        spotifyUrl: 'https://open.spotify.com/album/224SwtrqBG0hjnuM8gQtOf'
    },
    {
        index: 5,
        catalog: 'BASTANT006',
        artist: 'The Moodshapers',
        title: 'The Sunset Sessions',
        imgUrl:
            'https://img.discogs.com/lOqWGUcwm3p8i_tVlMaBF6gZkvg=/fit-in/600x600/filters:strip_icc():format(jpeg):mode_rgb():quality(90)/discogs-images/R-13387115-1553252455-2894.jpeg.jpg',
        discogsUrl:
            'https://www.discogs.com/The-Moodshapers-Swaytrick-The-Sunset-Sessions/release/13387115',
        spotifyUrl: 'https://open.spotify.com/album/03CtTV5aJ2UsBPBCudE5Gx'
    },
    {
        index: 6,
        catalog: 'BASTANT007',
        artist: 'Jonas Dunkel & Bredvidmannen',
        title: 'Markant Gung',
        imgUrl:
            'https://i.discogs.com/VqeXE5xIHnkKMVoNyIow87cM4uBTDGgBDRk3nvTsaqo/rs:fit/g:sm/q:90/h:600/w:600/czM6Ly9kaXNjb2dz/LWRhdGFiYXNlLWlt/YWdlcy9SLTEzNDQ0/Mzg5LTE2NjgyNDYy/MTEtNDE3OC5qcGVn.jpeg',
        discogsUrl:
            'https://www.discogs.com/Jonas-Dunkel-Bredvidmannen-Markant-Gung/release/13444389',
        spotifyUrl: 'https://open.spotify.com/album/469zFDwD2JsVjpZwSRLJPM'
    },
    {
        index: 7,
        catalog: 'BASTANT008',
        artist: 'Bredvidmannen & Jonas Dunkel',
        title: 'Makrillodlaren',
        imgUrl:
            'https://img.discogs.com/3jLJt2mLGoqxBXcfcOKnPMNcs-A=/fit-in/600x600/filters:strip_icc():format(jpeg):mode_rgb():quality(90)/discogs-images/R-13467862-1554762828-8358.jpeg.jpg',
        discogsUrl:
            'https://www.discogs.com/Bredvidmannen-Jonas-Dunkel-Makrillodlaren/release/13467862',
        spotifyUrl: 'https://open.spotify.com/album/6TWwDUgwMIBuAxsqsP55wa'
    },
    {
        index: 8,
        catalog: 'BASTANT009',
        artist: 'Jonas Dunkel',
        title: 'Monotone Funk',
        imgUrl:
            'https://img.discogs.com/JyEiCHkSVbNzLf0a3wGx7hHmUEA=/fit-in/600x600/filters:strip_icc():format(jpeg):mode_rgb():quality(90)/discogs-images/R-13505333-1555454946-5922.jpeg.jpg',
        discogsUrl: 'https://www.discogs.com/Jonas-Dunkel-Monotone-Funk/release/13505333',
        spotifyUrl: 'https://open.spotify.com/album/4SPUdHusK5Fc4QQYSb5U1a'
    },
    {
        index: 9,
        catalog: 'BASTANT010',
        artist: 'This Evening',
        title: 'Evening Ethics',
        imgUrl:
            'https://img.discogs.com/1F5OlyNgfq1GN43BoyebvqBzdjs=/fit-in/600x600/filters:strip_icc():format(jpeg):mode_rgb():quality(90)/discogs-images/R-13598048-1557230870-5071.jpeg.jpg',
        discogsUrl: 'https://www.discogs.com/This-Evening-Evening-Ethics/release/13598048',
        spotifyUrl: 'https://open.spotify.com/album/3Rr4GBJTB7qvkHSz7iX3hB'
    },
    {
        index: 10,
        catalog: 'BASTANT011',
        artist: 'Jonas Dunkel',
        title: 'Utterly Funked 2.0',
        imgUrl:
            'https://img.discogs.com/umUVvtSijQHvWVEho0PgUpPi8nE=/fit-in/600x600/filters:strip_icc():format(jpeg):mode_rgb():quality(90)/discogs-images/R-13751121-1560341623-9218.jpeg.jpg',
        discogsUrl: 'https://www.discogs.com/Jonas-Dunkel-Utterly-Funked-20/release/13751121',
        spotifyUrl: 'https://open.spotify.com/album/0Oc6BaB3xfRNTDXkwXqhOh'
    },
    {
        index: 11,
        catalog: 'BASTANT012',
        artist: 'Disco Gnomes',
        title: 'Those Loving Feelings',
        imgUrl:
            'https://img.discogs.com/rWrLT5ZUoITDMe1u63dgWsnbE8g=/fit-in/600x600/filters:strip_icc():format(jpeg):mode_rgb():quality(90)/discogs-images/R-13761637-1560550548-4233.jpeg.jpg',
        discogsUrl: 'https://www.discogs.com/Disco-Gnomes-Those-Loving-Feelings/release/13761637',
        spotifyUrl: 'https://open.spotify.com/album/0YjwkQN0jaXprDB5hBcGdc'
    },
    {
        index: 12,
        catalog: 'BASTANT013',
        artist: 'Jonas Dunkel',
        title: 'Midsummer Night Groove',
        imgUrl:
            'https://img.discogs.com/ogDWgxBpG3f83C_ROdCLCsBG_9w=/fit-in/600x600/filters:strip_icc():format(jpeg):mode_rgb():quality(90)/discogs-images/R-13781511-1560981721-2512.jpeg.jpg',
        discogsUrl: 'https://www.discogs.com/Jonas-Dunkel-Midsummer-Night-Groove/release/13781511',
        spotifyUrl: 'https://open.spotify.com/album/4ArMl6YtWGIMiadZeUV6z7'
    },
    {
        index: 13,
        catalog: 'BASTANT014',
        artist: 'Burt Brutal, Bredvidmannen & Jonas Dunkel',
        title: 'The Never Was',
        imgUrl:
            'https://img.discogs.com/msCYd6kt-kp9ZOx8X9dLYvjmZH4=/fit-in/600x600/filters:strip_icc():format(jpeg):mode_rgb():quality(90)/discogs-images/R-13817518-1561792856-3161.jpeg.jpg',
        discogsUrl:
            'https://www.discogs.com/Burt-Brutal-Bredvidmannen-Jonas-Dunkel-The-Never-Was/release/13817518',
        spotifyUrl: 'https://open.spotify.com/album/3K127o8oLBcDIooByVLQOJ'
    },
    {
        index: 14,
        catalog: 'BASTANT015',
        artist: 'Burt Brutal',
        title: 'Disturbed Disco Gnomes (Remastered)',
        imgUrl:
            'https://img.discogs.com/3AG6RIsIvJcdy4PftFRlQEoFzJ0=/fit-in/600x600/filters:strip_icc():format(jpeg):mode_rgb():quality(90)/discogs-images/R-13874735-1563059690-6881.jpeg.jpg',
        discogsUrl: 'https://www.discogs.com/Burt-Brutal-Disturbed-Disco-Gnomes/release/13874735',
        spotifyUrl: 'https://open.spotify.com/album/5CYbEctVhiwUrSbxAC7b7U'
    },
    {
        index: 15,
        catalog: 'BASTANT016',
        artist: 'Jonas Dunkel',
        title: 'Fovea 2.0.19',
        imgUrl:
            'https://img.discogs.com/rJCIjDs0QjZmZR7q81CFv2Lu0Y0=/fit-in/600x600/filters:strip_icc():format(jpeg):mode_rgb():quality(90)/discogs-images/R-13874747-1563059964-3868.jpeg.jpg',
        discogsUrl: 'https://www.discogs.com/Jonas-Dunkel-Fovea-2019/release/13874747',
        spotifyUrl: 'https://open.spotify.com/album/0hzO9Y14l3yr16Xob8NoYK'
    },
    {
        index: 16,
        catalog: 'BASTANT017',
        artist: 'Jonas Dunkel',
        title: 'Round & Round',
        imgUrl:
            'https://img.discogs.com/9KfN1SzK-RXcHUlvlWXHH3CNe8E=/fit-in/600x600/filters:strip_icc():format(jpeg):mode_rgb():quality(90)/discogs-images/R-14025752-1566327936-9989.jpeg.jpg',
        discogsUrl: 'https://www.discogs.com/Jonas-Dunkel-Round-Round/release/14025752',
        spotifyUrl: 'https://open.spotify.com/album/1JKowv81DD74ZfUGgpfsv0'
    },
    {
        index: 17,
        catalog: 'BASTANT018',
        artist: 'Deep Ministers',
        title: 'Dry Hand Wet Hand',
        imgUrl:
            'https://img.discogs.com/JYA1RjWUhO697Xu268GuNdi-yXI=/fit-in/600x600/filters:strip_icc():format(jpeg):mode_rgb():quality(90)/discogs-images/R-14354009-1572814521-3187.jpeg.jpg',
        discogsUrl: 'https://www.discogs.com/Deep-Ministers-Dry-Hand-Wet-Hand/release/14354009',
        spotifyUrl: 'https://open.spotify.com/album/3uclTF5JXCxX1MJ2fukBCx'
    },
    {
        index: 18,
        catalog: 'BASTANT019',
        artist: 'Deep Ministers',
        title: 'Low Evolvement',
        imgUrl:
            'https://img.discogs.com/Hf6NTvK818uzDguIEqPP6KOLj6E=/fit-in/600x600/filters:strip_icc():format(jpeg):mode_rgb():quality(90)/discogs-images/R-14395964-1573670753-3160.jpeg.jpg',
        discogsUrl: 'https://www.discogs.com/Deep-Ministers-Low-Evolvement/release/14395964',
        spotifyUrl: 'https://open.spotify.com/album/6mbAriLhU3SCYEF9OWoO9N'
    },
    {
        index: 19,
        catalog: 'BASTANT020',
        artist: 'Disco Gnomes',
        title: 'Piano Madness',
        imgUrl:
            'https://img.discogs.com/1dkMVqkCeXH-KkuAyMJWVqXITig=/fit-in/600x600/filters:strip_icc():format(jpeg):mode_rgb():quality(90)/discogs-images/R-14497972-1575757597-7046.jpeg.jpg',
        discogsUrl: 'https://www.discogs.com/Disco-Gnomes-Piano-Madness/release/14497972',
        spotifyUrl: 'https://open.spotify.com/album/7eEm9xnF1Z7pF1WGiZJHyC'
    },
    {
        index: 20,
        catalog: 'BASTANT021',
        artist: 'Deep Ministers',
        title: 'Low Evolvement (Disco Gnomes Remixes)',
        imgUrl:
            'https://img.discogs.com/tBnwd0afEyswBm74qqXWlStpvkA=/fit-in/600x600/filters:strip_icc():format(jpeg):mode_rgb():quality(90)/discogs-images/R-14423058-1578610860-6249.jpeg.jpg',
        discogsUrl:
            'https://www.discogs.com/Deep-Ministers-Low-Evolvement-Disco-Gnomes-Remixes/release/14423058',
        spotifyUrl: 'https://open.spotify.com/album/4wm2dS1Lp8RJzCsPCOfP5E'
    },
    {
        index: 21,
        catalog: 'BASTANT022',
        artist: 'Jonas Dunkel',
        title: 'Southern Exit',
        imgUrl:
            'https://i.discogs.com/YM3aGsrmAwf1FL58QxSNYbRxd3Qs8Hrzxb9s6Jirofc/rs:fit/g:sm/q:90/h:600/w:600/czM6Ly9kaXNjb2dz/LWRhdGFiYXNlLWlt/YWdlcy9SLTIyNDUx/MDQxLTE2NDY5MDQ0/NzUtODgzMC5wbmc.jpeg',
        discogsUrl:
            'https://www.discogs.com/release/22451041-Jonas-Dunkel-Southern-Exit',
        spotifyUrl: 'https://open.spotify.com/album/0xGjiw2ZHDo3xc7GKW24vY'
    },
    {
        index: 22,
        catalog: 'BASTANT023',
        artist: 'Deep Ministers',
        title: 'Over You (Remastered 2022)',
        imgUrl:
            'https://i.discogs.com/MzntBmc3OvpTsUKjKy6mFpMJHvUJFqs_Sds8vjaRj3s/rs:fit/g:sm/q:90/h:600/w:600/czM6Ly9kaXNjb2dz/LWRhdGFiYXNlLWlt/YWdlcy9SLTI0NDM0/NjI3LTE2NjI0ODk4/OTEtOTA3MS5qcGVn.jpeg',
        discogsUrl:
            'https://www.discogs.com/release/24434627-Deep-Ministers-Over-You-Remastered-2022',
        spotifyUrl: 'https://open.spotify.com/album/71Ca8YuWHYkw4Q6blZqsGC'
    },
    {
        index: 23,
        catalog: 'BASTANT024',
        artist: 'Deep Ministers',
        title: 'Higher Forces',
        imgUrl:
            'https://i.discogs.com/jwS5fjixCJNDSZO0oOzrObd3jgk6duhe1OBwDPsbDXQ/rs:fit/g:sm/q:90/h:600/w:600/czM6Ly9kaXNjb2dz/LWRhdGFiYXNlLWlt/YWdlcy9SLTI0NDM0/Njg3LTE2NjI0OTAz/MjUtMjIyOC5qcGVn.jpeg',
        discogsUrl:
            'https://www.discogs.com/release/24434687-Deep-Ministers-Higher-Forces',
        spotifyUrl: 'https://open.spotify.com/album/6DwQobM1ONjh3OLW3lV7NT'
    },
    {
        index: 24,
        catalog: 'BASTANT025',
        artist: 'Jon Puda',
        title: 'Let Me Love You (Swedish Vocoder Mixes)',
        imgUrl:
            'https://i.discogs.com/ncLhjOVVJpHJoLYawbiCFgysnDdWpMMRUDweZrNVyek/rs:fit/g:sm/q:90/h:600/w:600/czM6Ly9kaXNjb2dz/LWRhdGFiYXNlLWlt/YWdlcy9SLTI1MTM3/OTAxLTE2NjgyNDUx/ODItODUyOS5qcGVn.jpeg',
        discogsUrl:
            'https://www.discogs.com/release/25137901-Jon-Puda-Let-Me-Love-You-Swedish-Vocoder-Mixes',
        spotifyUrl: 'https://open.spotify.com/album/3e3nOySUmfQClQ2OQsDJAD'
    },
    {
        index: 25,
        catalog: 'BASTANT026',
        artist: 'Fiber Optic Friends',
        title: 'Sequencers (Remastered 2022)',
        imgUrl:
            'https://i.discogs.com/q3hRZgku9OtSvjPI_TfpCp9Bd-0PGbPkgvgRaQ5qUyA/rs:fit/g:sm/q:90/h:600/w:600/czM6Ly9kaXNjb2dz/LWRhdGFiYXNlLWlt/YWdlcy9SLTI1MTM3/OTM3LTE2NjgyNDU1/MjEtNjU1MS5qcGVn.jpeg',
        discogsUrl:
            'https://www.discogs.com/release/25137937-Fiber-Optic-Friends-Sequencers-Remastered-2022',
        spotifyUrl: 'https://open.spotify.com/album/1EvNpLLWiM6BKLsAGcfsEj'
    },
    {
        index: 26,
        catalog: 'BASTANT027',
        artist: 'The Moodshapers',
        title: 'Pool Music',
        imgUrl:
            'https://i.discogs.com/cmRtg8Ja7gkoqXCsZZpjeMASbP_cmThRMxvThuytnOE/rs:fit/g:sm/q:90/h:600/w:600/czM6Ly9kaXNjb2dz/LWRhdGFiYXNlLWlt/YWdlcy9SLTI1MTM3/OTc5LTE2NjgyNDYw/MDEtNDQwMi5qcGVn.jpeg',
        discogsUrl:
            'https://www.discogs.com/release/25137979-The-Moodshapers-Pool-Music',
        spotifyUrl: 'https://open.spotify.com/album/6dfQOFAGOoWvwqlw98mdFC'
    },
    {
        index: 27,
        catalog: 'BASTANT028',
        artist: 'Jonas Dunkel',
        title: 'Piano Man',
        imgUrl:
            'https://i.discogs.com/DGLMOz92eRejD2nwPG5Mj4WUdT-hoR3AumS_uMGz_OU/rs:fit/g:sm/q:90/h:600/w:600/czM6Ly9kaXNjb2dz/LWRhdGFiYXNlLWlt/YWdlcy9SLTI1MTk4/Njc1LTE2Njg3Mjcw/NDAtNjkyOC5qcGVn.jpeg',
        discogsUrl:
            'https://www.discogs.com/release/25198675-Jonas-Dunkel-Piano-Man',
        spotifyUrl: 'https://open.spotify.com/album/1fLj8KWMkRBMrHyc0YkLTJ'
    },
    {
        index: 28,
        catalog: 'BASTANT029',
        artist: 'Jonas Dunkel & Disco Gnomes',
        title: 'Piano Man (Disco Gnomes Twisted Disco Radio Edit)',
        imgUrl:
            'https://i.discogs.com/tFOw_uhQ7oHKEr61bpdMp4ISSnejFc4Yw4OaCjKk5FI/rs:fit/g:sm/q:90/h:600/w:600/czM6Ly9kaXNjb2dz/LWRhdGFiYXNlLWlt/YWdlcy9SLTI1MTk4/NzE0LTE2Njg3Mjc0/MTktNzY1NC5qcGVn.jpeg',
        discogsUrl:
            'https://www.discogs.com/release/25198714-Jonas-Dunkel-Disco-Gnomes-Piano-Man-Disco-Gnomes-Twisted-Disco-Radio-Edit',
        spotifyUrl: 'https://open.spotify.com/album/0wOTgfGuJNtFw46kt0jVj9'
    },
    {
        index: 29,
        catalog: 'BASTANT030',
        artist: 'The Moodshapers',
        title: 'Tears (Macaao Beach Club Mixes)',
        imgUrl:
            'https://i.discogs.com/S6nJY0zmbVVF3dFm7si4i-pL9TGp8KoS8-Yiz8DmIxs/rs:fit/g:sm/q:90/h:600/w:600/czM6Ly9kaXNjb2dz/LWRhdGFiYXNlLWlt/YWdlcy9SLTI1NDE3/NDYyLTE2NzA2MjE4/OTMtNTc5Ny5qcGVn.jpeg',
        discogsUrl:
            'https://www.discogs.com/release/25417462-The-Moodshapers-Tears-Macaao-Beach-Club-Mixes',
        spotifyUrl: 'https://open.spotify.com/album/0jFnqdlHyu1j3Dp54ogzoL'
    },
    {
        index: 30,
        catalog: 'BASTANT031',
        artist: 'Deep Ministers',
        title: 'Try',
        imgUrl:
            'https://i.discogs.com/z83X1SOsa0t1WmLXHfp3TaA1gCeCYm742J9MbcpzRSI/rs:fit/g:sm/q:90/h:600/w:600/czM6Ly9kaXNjb2dz/LWRhdGFiYXNlLWlt/YWdlcy9SLTI1NTc2/MzQ4LTE2NzIwODc5/NzgtNjUzNS5qcGVn.jpeg',
        discogsUrl:
            'https://www.discogs.com/release/25576348-Deep-Ministers-Try',
        spotifyUrl: 'https://open.spotify.com/album/2bFFGV1xK8zhwnI9IyGzcI'
    },      
];
