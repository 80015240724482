import React from 'react';
import LogoImage from '../../../assets/images/Beatport_MainLogo_White_Final.png';

export const Beatport = () => {
    return (
        <div className="box special logo">
            <a
                href="https://www.beatport.com/label/bastant/77573"
                target="_blank"
                rel="noopener noreferrer">
                <img
                    src={LogoImage}
                    width="250"
                    height="250"
                    alt="beatport-logo"
                    title="Beatport"
                />
            </a>
        </div>
    );
};
