import React from 'react';
import ReactGA from 'react-ga';
import { Discography } from '../Discography/Discography';
import './App.css';

const initializeReactGA = () => {
    ReactGA.initialize('UA-20540258-29');
    ReactGA.pageview('Startpage');
};

const App: React.FC = () => {
    initializeReactGA();

    return (
        <div className="App">
            <Discography />
        </div>
    );
};

export default App;
