import React from 'react';
import { StyledItem, StyledSoundCloud } from './Styles';

export const SoundCloud = () => {
    return (
        <StyledItem>
            <StyledSoundCloud>
                <a
                    href="https://soundcloud.com/bastant_se"
                    target="_blank"
                    rel="noopener noreferrer">
                    <img
                        className="cloud"
                        src="https://a-v2.sndcdn.com/assets/images/header/cloud-e365a47.png"
                        width="48"
                        height="22"
                        alt="soundcloud-cloud"
                        title="SoundCloud"
                    />
                    <br />
                    <img
                        className="wordmark"
                        src="https://a-v2.sndcdn.com/assets/images/header/wordmark-d95b0ac.png"
                        width="115"
                        height="22"
                        alt="soundcloud-wordmark"
                        title="SoundCloud"
                    />
                </a>
            </StyledSoundCloud>
        </StyledItem>
    );
};
