import React from 'react';
import { Logo } from './Logo';
import { SoundCloud } from './SoundCloud';
import { Discogs } from './Discogs';
import { Beatport } from './Beatport';
import { Release } from './Release';
import { releaseItems } from '../../../data/data';
import { DiscographyItems } from './Styles';

type DiscographyProps = {};

export const Discography: React.FC<DiscographyProps> = () => {
    const reversedItems = releaseItems.reverse();

    return (
        <DiscographyItems>
            <Logo />

            <SoundCloud />

            <Discogs />

            <Beatport />

            {reversedItems.map(releaseItem => (
                <Release key={releaseItem.catalog} {...releaseItem} />
            ))}
        </DiscographyItems>
    );
};
