import styled, { css } from 'styled-components';

export const DiscographyItems = styled.div(() => {
    return css`
        display: grid;
        grid-gap: 10px;
        grid-template-columns: repeat(auto-fit, 250px);
        grid-auto-rows: 250px;
        justify-content: center;
    `;
});

export const StyledItem = styled.div(() => {
    return css`
        background-color: #000;
        color: #ff7d01;
        height: 230px;
        padding: 10px;
        text-align: center;
        width: 230px;
    }
`;
});

export const StyledSoundCloud = styled.div(() => {
    return css`
        margin-top: 93px;
    `;
});

export const StyledDiscogs = styled.div(() => {
    return css`
        margin-top: 95px;
    `;
});
