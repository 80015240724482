import React from 'react';
// import LogoImage from '../../../assets/images/bastant-logo-square-600x600.jpg';
import LogoImage from '../../../assets/images/bastant-500x500.png';

export const Logo = () => {
    return (
        <div className="box special logo">
            <img src={LogoImage} width="250" height="250" alt="bastant-logo" title="BASTANT" />
        </div>
    );
};
