import React from 'react';

type ReleaseProps = {
    catalog: string;
    artist: string;
    title: string;
    discogsUrl: string;
    imgUrl: string;
    spotifyUrl?: string;
    soundcloudUrl?: string;
};

export const Release: React.FC<ReleaseProps> = ({ catalog, artist, title, discogsUrl, imgUrl }) => {
    return (
        <div className="box release">
            <div>
                <a href={discogsUrl} target="_blank" rel="noopener noreferrer">
                    <img
                        src={imgUrl}
                        width="250"
                        height="250"
                        alt={catalog}
                        title={artist + ' - ' + title + ' [' + catalog + ']'}
                    />
                </a>
            </div>
        </div>
    );
};
